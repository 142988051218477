$enable-gradients: true;

// Customize some defaults
$border-radius: .4rem;

$primary: #009E60;
$secondary: #f5cf87ff;

$primary-alt: #00CD1D !important;
$secondary-alt: #f5cf87ff;

$display-font-sizes: (
        1: 5rem,
        2: 4.5rem,
        3: 4rem,
        4: 3.5rem,
        5: 3rem,
        6: 2rem
);

.display-6 {
  font-family: "Noto Serif", sans-serif;
  font-weight: bold
}

.btn-primary {
  color: white !important;
}

@import "bootstrap/scss/bootstrap"; // TODO: dark

@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.bi {
  fill: currentcolor;
}

.ui-datepicker-calendar {
  display: none;
}
